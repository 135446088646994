import { Alert, Box, Button, Container, CssBaseline, TextField, ThemeProvider, useTheme } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from 'src/util/authenticationProvider';
import { PrizeDto } from 'src/util/types';
import config from '../../../../config';
import { useTranslation } from 'react-i18next';

export default function PrizeEdit(props:PrizeDto){
  const authContext = useAuth();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [prizes, setPrizes] = useState<PrizeDto[]>([]);
  const {t} = useTranslation();
   
  const handleSubmitData = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    const formData = new FormData(event.currentTarget);
    const bodyData = {
        id: props.id,
        description: formData.get("description") ? formData.get("description") : props.description,
        image: formData.get("image") ? formData.get("image") : props.image,
      }

    axios.post(config.apiUrl + "/api/prize/update", bodyData, {
      headers: {
        "Authorization": "Bearer " + authContext.token,
        "Content-Type": "multipart/form-data"
      }
    })
      .then(response => {
        console.log("update successful.");
        setSuccess('update successful.');
      })
      .catch(() => {
        console.log("Connection to Server failed.");
        setError('Connection to Server failed.')
      })

  }

  return(
    <>
      <ThemeProvider theme={useTheme}>
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box component="form" onSubmit={handleSubmitData} noValidate sx={{ mt: 1 }}>
        <TextField
            margin="normal"
            fullWidth
            id="description"
            label={t('field.Description')}
            name="description"
            autoComplete="description"
            defaultValue={props.description}
            autoFocus
          />
          <TextField
            margin="normal"
            type="file"
            required
            fullWidth
            id="image"
            name="image"
          />
        
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('general.Apply')}
          </Button>
        </Box>
        {error ? <Alert severity="error">{error}</Alert> : ''}
        {success ? <Alert severity="success">{success}</Alert> : ''}
      </Box>
    </Container>
  </ThemeProvider>
    </>
  );
}