import {
  styled,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';
import logo from './azg-logo.png'

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        align-items:center;
        justify-content:center;
        text-decoration: none;
        width: 100px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  const theme = useTheme();
  return (
      <LogoWrapper to="/cp/overview">
      <img src={logo} width='250px'/>
      </LogoWrapper>
  );
}

export default Logo;
