import {createContext, useContext, useState} from "react";
import {AccountData} from "./types";

interface AuthContextType {
    setIsAuthenticated: (flag: boolean) => void,
    isAuthenticated: boolean,
    setToken: (token: string) => void,
    token: string,
    setAccount: (account: AccountData) => void,
    account: AccountData,
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth muss innerhalb eines AuthProviders verwendet werden.')
    }
    return context;
}
interface AuthProviderProps {
    children: React.ReactNode
}
export default function AuthenticationProvider({children}: AuthProviderProps) {
    const storageToken = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!storageToken);
    const [token, setToken] = useState<string>(storageToken ? storageToken : '');
    const [account, setAccount] = useState<AccountData>(user ? JSON.parse(user) : {
        id: 0,
        profile: {
            firstname: "",
            lastname: "",
            birthDay:"",
            birthMonth:"",
            birthYear:""
        }
    });

    return (
        <AuthContext.Provider value={{isAuthenticated, setIsAuthenticated, token, setToken, account, setAccount}}>
            {children}
        </AuthContext.Provider>
    )
}