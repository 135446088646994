import { Box, Card, Container, CssBaseline, Grid, styled, ThemeProvider, Typography, useTheme } from '@mui/material';
import { t } from 'i18next';
import QRCode from 'qrcode.react';
import Logo from 'src/components/LogoSign';
import CurrentLottery from 'src/content/dashboards/Lottery/CurrentLottery';
import { useEffect, useState } from 'react';
import { Lottery } from 'src/util/types';
import axios from 'axios';
import config from '../../../config';

export default function QRCodeDisplayPage() {

  const [lottery, setLottery] = useState<Lottery>();
  const [qrCodeVal, setQrCodeVal] = useState<string>('');

  const fetchQRCodeValue = () => {
    axios.get(config.apiUrl + '/api/lottery/current')
      .then(res => {
        if (res.data.status >= 200) {
          setLottery(res.data.content);
        }
      })
      .catch(err => console.log("Couldn't fetch current Game", err));
  };
  useEffect(() => {
    fetchQRCodeValue();
    // Intervall einrichten
    const interval = setInterval(fetchQRCodeValue, 5000); // 5000ms = 5 Sekunden

    // Bereinigung bei Komponentendemontage
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (lottery) {
      const path = `https://azg-gewinnspiel.de/join/${lottery.id}`;
      setQrCodeVal(path);
    } else {
      setQrCodeVal('');
    }
  }, [lottery]);


  return (
    <>
      <ThemeProvider theme={useTheme}>
        <Container component="main" maxWidth="lg"
          sx={{
            marginTop: '30px'
          }}>
          <Card>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={1}
            >
              <Grid item md={4} xs={12}>
               

                  {qrCodeVal ?
                    <Box
                      sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxHeight: '600px'
                      }}
                    >
                      <Logo />
                      <Typography component="h1" variant="h5">
                        Jetzt teilnehmen
                      </Typography>
                      <Box
                        sx={{
                          marginTop: 3,
                          marginBottom: 8,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <Card 
                        sx={{
                          padding:'25px'
                        }}
                        >
                                                 <QRCode value={qrCodeVal} size={256} />
 
                        </Card>
                      </Box>
                    </Box>
                    :
                    <Box
                      sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxHeight: '600px'
                      }}
                    >
                      <Logo />
                      <Typography component="h1" variant="h4"
                        sx={{
                          marginBottom: '15px'
                        }}>
                        Derzeit sind keine Gewinnspiele verfügbar.
                      </Typography>
                    </Box>
                  }

               
              </Grid>

              <Grid item md={8}
                xs={12}>
                <Grid item md={12} xs={12} >
                  {lottery ? <CurrentLottery lotteryData={lottery} /> : 
                  <Box 
                  sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    flexDirection:'column',
                    padding:'30px'
                  
                  }}>
                    <h1>Bewerten Sie Uns</h1>
                    <QRCode value='https://go.ennorev.de/azg' />
                  </Box>
                
                  }
                </Grid>
              </Grid>
            </Grid>
          </Card>
     
         
          <CssBaseline />
        </Container>
      </ThemeProvider >
    </>
  );
}