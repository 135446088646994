import { Card } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from 'src/util/authenticationProvider';
import CustomTable from 'src/content/pages/management/lottery/CustomTable';
import config from '../../../../config';
import { useTranslation } from 'react-i18next';

export default function ParticipationHistory(){

  const authContext = useAuth();
  const [participants, setParticipants] = useState<any[]>([]);
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    axios.get(config.apiUrl + '/api/lottery/all/participants', {
      headers: {
        'Authorization': 'Bearer ' + authContext.token
      }
    })
      .then(response => {
        //console.log(response.data);
        setParticipants(response.data.content);

      })
      .catch(err => {
        console.log(err)
      })
    setLoading(false);
  }, []);
  return(
    <Card>
      <CustomTable title={t('title.Participants')} list={participants} isLoading={loading}/>
    </Card>
  );
}