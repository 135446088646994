import { useAuth } from '../../../../util/authenticationProvider';
import { useEffect, useState } from 'react';
import * as React from 'react';
import axios from 'axios';
import { Alert, Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, List, ListItemButton, ListItemIcon, ListItemText, Paper, TextField, ThemeProvider, useTheme } from '@mui/material';
import { t } from 'i18next';
import { PrizeDto, Lottery } from 'src/util/types';
import config from '../../../../config';
import LotteriesList from './LotteriesList';
import { useTranslation } from 'react-i18next';

function not(a: readonly string[], b: readonly string[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly string[], b: readonly string[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}


export default function LotteryManagement() {
  const authContext = useAuth();
  const {t} = useTranslation();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [checked, setChecked] = React.useState<readonly string[]>([]);
  const [prizeList, setPrizeList] = useState<readonly PrizeDto[]>([]);
  const [left, setLeft] = React.useState<readonly string[]>([]);
  const [right, setRight] = React.useState<readonly string[]>([]);
  const [lotteries, setLotteries] = useState<Lottery[]>([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  let prizeArray = [];
  const getPrizeList = async () => {
    await axios.get(config.apiUrl + "/api/prize/all", {
      headers: {
        "Authorization": "Bearer " + authContext.token
      }
    }).then((pList) => {
      setPrizeList(pList.data.content)
      pList.data.content.map(p => {
        prizeArray = prizeArray.concat(p.id.toString());
      }
      )
      setLeft(prizeArray);

    })
  }

  const getLotteries = async () => {
    await axios.get(config.apiUrl + "/api/lottery/all", {
      headers: {
        "Authorization": "Bearer " + authContext.token
      }
    }).then((pList) => {
      setLotteries(pList.data.content);
    }
    )
  }


  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (items: readonly string[]) => (
    <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value: string, key) => {
          var label = '';
          prizeList.map(prizeItem => {
            if(value.includes(prizeItem.id.toString())){
              label = prizeItem.description;
            }

          })
          const labelId = `transfer-list-item-${value}-label`;
          return (
            <ListItemButton
              key={value}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={label} />
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );


  const handleSubmitData = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');

    const formData = new FormData(event.currentTarget);
    const bodyData = {
      startDate: formData.get("startDate"),
      endDate: formData.get("endDate"),
      name: formData.get("name"),
      prizes: Object.fromEntries(right.map((v, k) => [k + 1, v]))
    }
    axios.post(config.apiUrl + "/api/lottery/create", bodyData, {
      headers: {
        'Authorization': 'Bearer ' + authContext.token
      }
    })
      .then(res => {
        setSuccess('Creation successful.');
        setLotteries([...lotteries, res.data.content]);
      })
      .catch(() => {
        setError('Connection to Server failed.')
      })
  }

  useEffect(() => {
    getPrizeList();
    getLotteries();
  }, [])


  return (
    <>
      <ThemeProvider theme={useTheme}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="form" onSubmit={handleSubmitData} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                autoFocus
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                margin="normal"
                type="datetime-local"
                required
                fullWidth
                id="startDate"
                name="startDate"
                label={t('field.StartDate')}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                margin="normal"
                type="datetime-local"
                required
                fullWidth
                name="endDate"
                id="endDate"
                label={t('field.EndDate')}
                InputLabelProps={{ shrink: true }}
              />

              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item>{customList(left)}</Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      {t('general.Add')} &gt;
                    </Button>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      &lt; {t('general.Remove')}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>{customList(right)}</Grid>
              </Grid>


              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {t('general.Create')}
              </Button>
            </Box>
            {error ? <Alert severity="error">{error}</Alert> : ''}
            {success ? <Alert severity="success">{success}</Alert> : ''}
          </Box>
          <LotteriesList rows={lotteries}/>
        </Container>
      </ThemeProvider>
    </>
  );
}