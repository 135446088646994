import { useAuth } from 'src/util/authenticationProvider';
import { useEffect, useState } from 'react';
import * as React from 'react';
import axios from 'axios';
import { Alert, Card } from '@mui/material';
import CustomTable from '../lottery/CustomTable';
import { AccountData } from 'src/util/types';
import config from '../../../../config';
import { useTranslation } from 'react-i18next';

export default function UserManagement() {
  const authContext = useAuth();
  const [accounts, setAccounts] = useState<AccountData[]>([]);
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    axios.get(config.apiUrl + "/api/accounts", {
      headers: {
        'Authorization': 'Bearer ' + authContext.token
      }
    })
      .then(response => {
        setAccounts(response.data.content);
      })
      .catch(err => console.log(err))
    setLoading(false);
  }, []);
  return(
    <Card>
      <CustomTable list={accounts} title={t('title.Accounts')} isLoading={loading}/>
    </Card>
  );
}