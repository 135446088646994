import { useAuth } from '../../../../util/authenticationProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../../../config';
import { FilledInput, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import { SettingEntry } from '../../../../util/types';
import { useTranslation } from 'react-i18next';


export default function SystemSettings(){
  const authContext = useAuth();
  const [settings, setSettings] = useState<SettingEntry[]>([]);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const {t} = useTranslation();

  useEffect(() => {
    axios.get(config.apiUrl + '/api/settings/system', {
      headers: {
        'Authorization': 'Bearer ' + authContext.token
      }
    })
      .then(res => {
        if (res.data.status >= 200) {
          console.table(res.data.content);
          setSettings(res.data.content);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        setIsSuccess(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  const upsertSetting = (setting: SettingEntry) => {
    setSettings(prevSettings => {
      const existingIndex = prevSettings.findIndex(prevSetting => prevSetting.key === setting.key);
      if (existingIndex >= 0) {
        // Schlüssel existiert, ersetze den Eintrag
        const updatedSettings = [...prevSettings];
        updatedSettings[existingIndex] = setting;
        return updatedSettings;
      } else {
        // Schlüssel existiert nicht, füge neuen Eintrag hinzu
        return [...prevSettings, setting];
      }
    });
    setIsChanged(true);
  }
  const handleChangeValue = (key: string, val: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    val.preventDefault();
    let setting: SettingEntry = {
      key: key,
      value: val.currentTarget.value
    }
    upsertSetting(setting);
  }

  const handleSettingUpdate = () => {
    axios.post(config.apiUrl + "/api/settings/system/update", settings,{
      headers: {
        'Authorization': 'Bearer ' + authContext.token
      }
    }).then(res => {
      if (res.data.status >= 200) {
        console.log("success");
        setIsSuccess(true);
      }
    })
      .catch(err => console.error(err))
  }
  return (
    <>
      <TableContainer>
        <h1>{t('sidebar.SystemSettings')}</h1>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><Button disabled={!isChanged} onClick={handleSettingUpdate}>{t('general.Apply')}</Button></TableCell>
            </TableRow>
            {isSuccess ? <p>Success</p> : ''}
          </TableHead>
          <TableBody>
            {settings.sort((setting1, setting2) => {
              return setting1.key.localeCompare(setting2.key);
            }).map(entry => {
              return (<TableRow key={entry.key}>
                <TableCell>{entry.key}</TableCell>
                <TableCell><FilledInput value={entry.value} onChange={val => handleChangeValue(entry.key, val)}/></TableCell>
              </TableRow>);
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}