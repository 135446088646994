import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { Lottery, PrizeDto } from 'src/util/types';

import { useState } from 'react';
import { propsToClassKey } from '@mui/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Typography } from '@mui/material';
import axios from 'axios';
import config from 'src/config';
import { useAuth } from 'src/util/authenticationProvider';
import LotteryManagement from './LotteryManagement';
import LotteryEdit from '../LotteryEdit';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const formatDate = (value) => {
  return new Date(value).toLocaleDateString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  });
};

const formatPrizes = (value: Map<number, PrizeDto>) => {
  let prizeLabel = '';
  Object.entries(value).map(([key, val]) => {
    prizeLabel = prizeLabel.concat(' | ', val.description);
  })
  return prizeLabel;
}

const columns: GridColDef<Lottery[]>[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    width: 170,
    valueFormatter: value =>
      formatDate(value),
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    width: 170,
    valueFormatter: value =>
      formatDate(value),
  },
  {
    field: 'active',
    headerName: 'Active',
    width: 100,
  },
  {
    field: 'closed',
    headerName: 'Closed',
    width: 100,
  },
  {
    field: 'prizes',
    headerName: 'Prizes',
    width: 270,
    valueFormatter: (value: Map<number, PrizeDto>) => formatPrizes(value)
  },
];



export default function LotteriesList(props: { rows }) {
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const navigate=useNavigate();
  const {t} = useTranslation();
  const handleGetSelectedRow = () => {
    if (selectedRow !== null) {
      console.log('Selected Row ID:', selectedRow);
    } else {
      alert('No row selected');
    }
  };
  const authContext = useAuth();

  const deleteLottery = () => {
    axios.delete(config.apiUrl + '/api/lottery/remove/' + selectedRow.id, {
      headers: {
        'Authorization': 'Bearer ' + authContext.token
      }
    })
      .then(res => {
        if (res.data.status >= 200) {
          setOpenDialog(false)
        }
      })
      .catch(error=>{
        if (error.response.status === 401) {
          authContext.setAccount(null);
          authContext.setToken('');
          authContext.setIsAuthenticated(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate('/cp/login')
         }
      });
  }

  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box sx={{ width: '100%', backgroundColor: '#fff' }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('title.EditLottery')}
          </Typography>
          <Box id="modal-modal-description" sx={{ mt: 2 }}>
            <LotteryEdit {...selectedRow} />
          </Box>
        </Box>
      </Modal>
      <Button disabled={!selectedRow} onClick={handleOpen}>{t('general.Edit')}</Button>
      <Button disabled={!selectedRow} onClick={handleOpenDialog}>{t('general.Delete')}</Button>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('title.DeleteItem')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('alert.Confirmation')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t('general.No')}</Button>
          <Button onClick={deleteLottery} autoFocus>
            {t('general.Yes')}
          </Button>
        </DialogActions>
      </Dialog>

      <DataGrid
        rows={props.rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        isRowSelectable={(params: GridRowParams) => params.row.closed == false}
        pageSizeOptions={[10]}
        checkboxSelection
        disableMultipleRowSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = props.rows.filter((row) =>
            selectedIDs.has(row.id.toString())
          );
          setSelectedRow(selectedRowData[0]);
        }}
      />
    </Box>
  );
}
