import { Box, Card, CardMedia, Typography } from "@mui/material"
import { PrizeDto } from "src/util/types"


function PrizeCard(props: PrizeDto) {
    return (
        <div>
        <Card
            sx={
                {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    margin: '5px',
                    width: '320px',
                    height: '100px'
                }
            }>

            <CardMedia
                component="img"
                sx={{
                    width: 140,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
                image={props.image}
                alt={props.description}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '5px', textAlign: 'center' }}>
                <Typography variant="h4">{props.description}</Typography>
            </Box>
        </Card>
        </div>
    )
}

export default PrizeCard