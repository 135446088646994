import { useNavigate, useParams } from 'react-router';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Logo from 'src/components/LogoSign';
import config from '../../../config';
import { InputAdornment } from '@mui/material';


export default function JoinLotteryPage() {

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const requestData = {
      email: formData.get('email'),
      phone: '+49' + formData.get('phone').toString().replace(/^0+/, ''),
      name: formData.get('name'),
      privacyAccepted: formData.get('privacyAccepted')
    };
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(requestData)
    };
    const response = await fetch(config.apiUrl + `/api/lottery/join/${id}`, requestOptions);
    const data = await response.json()
      .then(response => {
        console.log(response);
        var luckynumber = response.content;
        navigate("/lucky-number", { state: { lotteryId: id, luckyNumber: luckynumber } });
      })
      .catch(err => console.log("Couldn't fetch current Game", err));
  }

  return (
    <ThemeProvider theme={useTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Logo />
          <br />
          <Typography component="h1" variant="h5">
            Jetzt teilnehmen
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              inputProps={{
                type: "email",
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="phone"
              label="Phone"
              id="phone"
              autoComplete="phone"
              sx={{ 
                'input::-webkit-inner-spin-button' : {
                appearance: 'none',
                margin: 0
              }
        
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">+49</InputAdornment>,
                type: "number",
              }}
            />
            <FormControlLabel
              name="privacyAccepted"
              id="privacyAccepted"
              control={<Checkbox value="active" color="primary" required />}
              label={<a href={"/privacy"}>Datenschutz</a>}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t('general.Join')}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}