import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import LotteryOverview from './content/pages/management/lottery/LotteryOverview';
import UserManagement from './content/pages/management/user/UserManagement';
import LotteryManagement from './content/pages/management/lottery/LotteryManagement';
import PrizeManagement from './content/pages/management/prize/PrizeManagement';
import ParticipationHistory from './content/pages/history/participation/ParticipationHistory';
import NotificationSettings from './content/pages/settings/notification/NotificationSettings';
import SystemSettings from './content/pages/settings/system/SystemSettings';
import QRCodeDisplayPage from './content/pages/external/QRCodeDisplayPage';
import JoinLotteryPage from './content/pages/external/JoinLotteryPage';
import LuckyNumberPage from './content/pages/external/LuckyNumberPage';
import ControlPanelLogin from './content/pages/ControlPanelLogin';
import Overview from './content/overview';
import DashboardLotteries from './content/dashboards/Lottery';
import PrivateRoute from './util/privateRoute';
import { useAuth } from './util/authenticationProvider';
import CurrentLottery from './content/dashboards/Lottery/CurrentLottery';
import PrivacyPage from './content/pages/external/PrivacyPage';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

export default function routing() {
  const authContext = useAuth();

  const routes: RouteObject[] = [
    {
      path: '',
      element: <BaseLayout />,
      children: [
        {
          path: '/',
          element: <StatusComingSoon />
        },
        {
          path: '/privacy',
          element: <PrivacyPage />
        },
        {
          path: '/join/:id',
          element: <JoinLotteryPage />
        },
        {
          path: '/lucky-number',
          element: <LuckyNumberPage />
        },
        {
          path: 'status',
          children: [
            {
              path: '',
              element: <Navigate to="404" replace />
            },
            {
              path: '404',
              element: <Status404 />
            },
            {
              path: '500',
              element: <Status500 />
            },
            {
              path: 'maintenance',
              element: <StatusMaintenance />
            },
            {
              path: 'coming-soon',
              element: <StatusComingSoon />
            }
          ]
        },
        {
          path: '*',
          element: <Status404 />
        }
      ]
    },
    {
      path: 'cp',
      element: <BaseLayout />,
      children: [
        {
          path: '',
          element: <Navigate to="login" replace/>
        },
        {
          path: 'login',
          element: <ControlPanelLogin />
        },
        {
          path: 'qrcode',
          element: <PrivateRoute isAuthenticated={authContext.isAuthenticated} component={QRCodeDisplayPage} />
        },
        {
          path: 'overview',
          element: <PrivateRoute isAuthenticated={authContext.isAuthenticated} component={SidebarLayout}/>,
          children: [
            {
              path: '',
              element: <QRCodeDisplayPage />
            }
          ]
        },
        {
          path: 'management',
          element: <PrivateRoute isAuthenticated={authContext.isAuthenticated} component={SidebarLayout} />,
          children: [
            {
              path: '',
              element: <Navigate to="users" replace />
            },
            {
              path: 'users',
              element: <UserManagement />
            },
            {
              path: 'lotteries',
              element: <LotteryManagement />
            },
            {
              path: 'prizes',
              element: <PrizeManagement />
            }
          ]
        },
        {
          path: 'history',
          element: <PrivateRoute isAuthenticated={authContext.isAuthenticated} component={SidebarLayout} />,
          children: [
            {
              path: 'participation',
              element: <ParticipationHistory />
            }
          ]
        },
        {
          path: 'settings',
          element: <PrivateRoute isAuthenticated={authContext.isAuthenticated} component={SidebarLayout} />,
          children: [
            {
              path: 'notifications',
              element: <NotificationSettings />
            },
            {
              path: 'system',
              element: <SystemSettings />
            }
          ]
        }
      ]
    }
  ];
  return routes;
}

