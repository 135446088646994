import { useNavigate } from 'react-router';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Alert } from '@mui/material';
import { useAuth } from '../../util/authenticationProvider';
import config from '../../config';


export default function ControlPanelLogin() {

  const { t } = useTranslation();
  const authContext = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    profile: {
      firstname: ''
    }
  });
  const [error, setError] = useState('');
  const handleFetchData = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    setUser({
      profile: {
        firstname: ''
      }
    });
    const formData = new FormData(event.currentTarget);
    const fdata = {
      username: formData.get('username'),
      password: formData.get('password'),
    };
    console.log('data', fdata)
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(fdata)
    };
    const response = await fetch(config.apiUrl + '/api/login', requestOptions);
    const data = await response.json()
      .then(loginResponse => {
        var token = loginResponse.content.token;
        var account = loginResponse.content.account;
        authContext.setAccount(account);
        authContext.setToken(token);
        authContext.setIsAuthenticated(true);
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(account));
        navigate('/cp/overview')
      })
      .catch(err => setError('Error, please check username or password'));

  }

  return (
    <ThemeProvider theme={useTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleFetchData} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {error ? <Alert severity="error">{error}</Alert> : ''}


            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t('general.Signin')}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}