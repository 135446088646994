import { Box, Card } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import CurrentLottery from 'src/content/dashboards/Lottery/CurrentLottery';
import { Lottery } from 'src/util/types';
import config from '../../../config';


export default function LuckyNumberPage() {
  const location = useLocation();

  const [lottery, setLottery] = useState<Lottery>();
  useEffect(() => {
    axios.get(config.apiUrl + '/api/lottery/current')
      .then(res => {
        if (res.data.status >= 200) {
          setLottery(res.data.content);
        }
      })
      .catch(err => console.log("Couldn't fetch current Game", err));
  });


  return (
    <>
      <Box p={1}>

        {lottery ?
          <Card>
            <Card
              sx={{
                textAlign: 'center'
              }}
            >
              <p>Thank you, You lucky number :</p>
              <h1>{location.state['luckyNumber']}</h1>
            </Card>

            <CurrentLottery lotteryData={lottery} />
          </Card>
          : 'No lotteries found'}

      </Box>

    </>
  );
}