import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import {  PrizeDto } from 'src/util/types';

import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Typography } from '@mui/material';
import axios from 'axios';
import config from 'src/config';
import { useAuth } from 'src/util/authenticationProvider';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import PrizeEdit from './PrizeEdit';





export default function PrizeList(props: { rows, deleteCallback }) {
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const navigate=useNavigate();
  const authContext = useAuth();
  const {t} = useTranslation();
  const columns: GridColDef<PrizeDto[]>[] = [
    {
      field: 'description',
      headerName: t('field.Description'),
      width: 250,
    },
    {
      field: 'image',
      headerName: t('field.Image'),
      width: 450,
      renderCell: (params) => <img width={450} height={150} src={params.value} />,
    }
  ];
  const deletePrize = () => {
    axios.delete(config.apiUrl + '/api/prize/remove/' + selectedRow.id, {
      headers: {
        'Authorization': 'Bearer ' + authContext.token
      }
    })
      .then(res => {
        if (res.data.status >= 200) {
          setOpenDialog(false)
        }
        props.deleteCallback(selectedRow.id);
      })
      .catch(error=>{
        if (error.response.status === 401) {
          authContext.setAccount(null);
          authContext.setToken('');
          authContext.setIsAuthenticated(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate('/cp/login')
         }
         alert('Der Prize wird bereits verwendet und kann nicht gelöscht werden')
      });
  }


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box sx={{ width: '100%', backgroundColor: '#fff' }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('title.EditPrize')}
          </Typography>
          <Box id="modal-modal-description" sx={{ mt: 2 }}>
            <PrizeEdit {...selectedRow} />
          </Box>
        </Box>
      </Modal>
      <Button disabled={!selectedRow} onClick={handleOpen}>{t('general.Edit')}</Button>
      <Button disabled={!selectedRow} onClick={handleOpenDialog}>{t('general.Delete')}</Button>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('title.DeleteItem')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('alert.Confirmation')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t('general.No')}</Button>
          <Button onClick={deletePrize} autoFocus>
            {t('general.Yes')}
          </Button>
        </DialogActions>
      </Dialog>
      
      <DataGrid
        rows={props.rows}
        rowHeight={160}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        checkboxSelection
        disableMultipleRowSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = props.rows.filter((row) =>
            selectedIDs.has(row.id)
          );
          setSelectedRow(selectedRowData[0]);
        }}
      />
    </Box>
  );
}
