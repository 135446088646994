import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          sidebar:{
            CustomerView:'Customer View',
            CurrentLottery:'Current Lottery',
            Overview:'Overview',
            Users:'Users',
            Lotteries:'Lotteries',
            Prizes:'Prizes',
            History:'History',
            ParticipationHistory:'Participation History',
            Settings:'Settings',
            NotificationSettings:'Notification Settings',
            SystemSettings:'System Settings'
          
          },
          general:{
            Signin:'Sign in',
            Join:'Join',
            Edit:'Edit',
            Create:'Create',
            Delete:'Delete',
            Yes:'Yes',
            No:'No',
            Apply:'Save',
            Add:'Add',
            Remove:'Remove'
          },
          title:{
            EditPrize:'Edit Prize',
            EditLottery:'Edit Lottery',
            DeleteItem:'Delete Item',
            Participants:'Participants',
            Accounts:'Accounts'
          },
          alert:{
            Confirmation:'Are you sure that you want to execute this action?'
          },
          field:{
            Description:'Description',
            Image:'Image',
            StartDate:'Start Date',
            EndDate:'End Date'
          }
        }
      },
      de:{
        translation:{
          sidebar:{
            CustomerView:'Kunden Startseite',
            CurrentLottery:'Aktuelles Gewinnspiel',
            Overview:'Übersicht',
            Users:'Benutzer',
            Lotteries:'Gewinnspiele',
            Prizes:'Gewinne',
            History:'Historie',
            ParticipationHistory:'Teilnahme Historie',
            Settings:'Einstellungen',
            NotificationSettings:'Nachricht Einstellungen',
            SystemSettings:'System Einstellungen'
          
          },
          general:{
            Signin:'Einloggen',
            Join:'Teilnehmen',
            Edit:'Bearbeiten',
            Create:'Erstellen',
            Delete:'Löschen',
            Yes:'Ja',
            No:'Nein',
            Apply:'Speichern',
            Add:'Hinzufügen',
            Remove:'Entfernen'
          },
          title:{
            EditPrize:'Preis bearbeiten',
            EditLottery:'Gewinnspiel bearbeiten',
            DeleteItem:'Eintrag löschen',
            Participants:'Teilnehmer',
            Accounts:'Benutzerkonten'
          },
          alert:{
            Confirmation:'Sind Sie sich sicher das Sie diese Aktion ausführen wollen?'
          },
          field:{
            Description:'Beschreibung',
            Image:'Bild',
            StartDate:'Start Datum',
            EndDate:'End Datum'
          }
        }
      }
    }
  });

export default i18n;