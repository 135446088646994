import { Navigate, Route, RouteProps } from 'react-router';
import * as React from 'react';
import { Authority } from './types';
import { ComponentType } from 'react';


interface PrivateRouteProps extends RouteProps{
  isAuthenticated: boolean,
  component: ComponentType<any>,
  requiredRole?: string,
  authorities?: Authority[],
}

export default function PrivateRoute(routeProps: PrivateRouteProps) {
  const {
    isAuthenticated,
    component: Component,
    requiredRole,
    authorities,
    ...rest
  } = routeProps;
  function handleAuthError() {
    if (isAuthenticated) {
      return <Navigate to="/status/401" />;
    }
    return <Navigate to="/login" />;
  }

  if (requiredRole) {
    if (authorities) {
      const hasRequiredRole = authorities.some(
        (auth) => auth.id.toUpperCase() === requiredRole.toUpperCase()
      );
      if (hasRequiredRole) {
        return (
          <Component />
        );
      } else {
        return handleAuthError();
      }
    } else {
      return handleAuthError();
    }
  } else {
    if (isAuthenticated) {
      return (
        <Component />
      );
    } else {
      return (
        <Navigate to="/"/>
      );
    }
  }
}