import { Grid, Badge, Box } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import PrizeCard from "src/components/PrizeCard/PrizeCard";
import config from "src/config";
import { useAuth } from "src/util/authenticationProvider";
import { PrizeDto } from "src/util/types";
import Marquee from "react-fast-marquee";


 
export default function PossiblePrizes() {
    const authContext = useAuth();
    const [prizes, setPrizes] = useState<PrizeDto[]>([]);
    const getPrizesList = async () => {
        await axios.get(config.apiUrl + "/api/prize/all", {
            headers: {
                'Authorization': 'Bearer ' + authContext.token
            }
        })
            .then(response => {
                setPrizes(response.data.content);
            })
            .catch(err => console.log(err))
    }


    useEffect(() => {
        getPrizesList();
    }, []);
    return (
        <>
<Box textAlign="center">
    <h1>Das Können Sie gewinnen </h1>
</Box>
            
            <Grid
                xs={12}
                sm={12}
                item
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                flexDirection="row"
                marginBottom='15px'
            >
             <Marquee>
                {prizes.map((value, key) => {
                    return (
                        <PrizeCard key={key} {...value} />
                    )
                })
                }
               </Marquee>
            </Grid>
        </>
    )
}