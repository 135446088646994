import {
  Box,
  Card, CardContent,
  CardHeader,
  Divider,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { Add, SearchRounded } from '@mui/icons-material';

interface CustomTableProps {
  list: any[],
  title: string,
  isLoading: boolean
}

const isDate = (value) => {
  if (value.length <= 2) {
    return false;
  }
  const dateRegexStandard = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
  const dateRegexLocalDateTime = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])T([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/
  if (dateRegexStandard.test(value)) {
    return true;
  } else return dateRegexLocalDateTime.test(value);
};

const isImage = (value) => {
  const base64Pattern = /^data:image\/(jpeg|png|gif|bmp|webp);base64,/;
  return base64Pattern.test(value);
};
const formatDate = (value) => {
  return new Date(value).toLocaleDateString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  });
};
export default function CustomTable(props: CustomTableProps) {

  const [modalActive, setModalActive] = useState<boolean>();
  const [spectatorObj, setSpectatorObj] = useState<any>();
  const [parentTitle, setParentTitle] = useState<string>('');
  const handleOpen = (value, field) => {
    setSpectatorObj(value);
    setParentTitle(field);
    setModalActive(true);
  };
  const handleClose = () => setModalActive(false);

  const style = (elements: any[]) => {
    return {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: (elements.length * 200),
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4
    };

  };


  return (
    <Card>
      <CardHeader title={props.title} />
      <Divider />
      <Modal
        open={modalActive}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {spectatorObj ?
          <Box sx={style(Object.keys(spectatorObj))}>
            {generateTable(spectatorObj, parentTitle, handleOpen)}
          </Box>
          : <Box>...Empty</Box>}

      </Modal>
      {props.list.length > 0 ?
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(props.list.at(0)).map(field => {
                  return <TableCell key={field}>{field}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.list.map((entry, index) => {
                return (
                  <TableRow key={index}>
                    {Object.keys(entry).map(field => {
                      return <TableCell key={field + index}>
                        {renderContent(entry[field], field, handleOpen)}
                      </TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        : props.isLoading ?
          <CardContent>Loading...</CardContent> :
          <CardContent>No Data</CardContent>
      }
    </Card>
  );
}

const isNumeric = (value: string): boolean => /^[0-9]+$/.test(value);
const renderContent = (value: any, field: string, handleOpen: (value, field) => void) => {
  if (Array.isArray(value)) {
    return value.map((val, index) => (
      <span key={index}>
        {typeof val === 'object' && val !== null && Object.keys(val).length > 0 ? (
          <>
            {val[Object.keys(val)[0]]}
            <IconButton size="small" onClick={() => handleOpen(val, field)}>
              <SearchRounded />
            </IconButton>
          </>
        ) : (
          renderContent(val, field, handleOpen)
        )}
      </span>
    ));
  }

  if (typeof value === 'object' && value !== null) {
     if(Object.keys(value).length > 0) {
       if(isNumeric(Object.keys(value)[0])){
         return (
           <>
             {typeof value[Object.keys(value)[0]] !== 'object' ? value[Object.keys(value)[0]] :
               <IconButton size="small" onClick={() => handleOpen(value, field)}>
                 <SearchRounded />
               </IconButton>
             }
           </>
         )
       } else {
         return (
           <>
             {value[Object.keys(value)[0]]}
               <IconButton size="small" onClick={() => handleOpen(value, field)}>
                 <SearchRounded />
               </IconButton>
           </>
         )
       }

     } else {
       return ('N/A');
     }
  }

  if (typeof value === 'boolean') {
    return value ? 'true' : 'false';
  }

  if (typeof value === 'string') {
    if (isDate(value)) {
      return formatDate(value);
    }
    if (isImage(value)) {
      return <img width={100} height={100} src={value} alt="" />;
    }
    return value;
  }

  return value ?? 'N/A';
};
const generateTable = (spectatorObj: any, parentTitle: string, handleOpen: (value, field) => void) => {


  const generateTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          {isNumeric(Object.keys(spectatorObj)[0]) ?
            <><TableCell>
              No.
            </TableCell>
            <TableCell>
              Object
            </TableCell>
            </> :
            <>
            {Object.keys(spectatorObj).map((key) => {
               return (<TableCell key={key}>{key}</TableCell>)
            })}
            </>
          }
        </TableRow>
      </TableHead>
    );
  };

  const generateTableBody = () => {
    return (
      <TableBody>
        {isNumeric(Object.keys(spectatorObj)[0]) ?
          Object.keys(spectatorObj).map((key) => {
            return (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>{typeof spectatorObj[key] === 'object' ?
                  <span>{parentTitle}
                        <IconButton size="small" onClick={() => handleOpen(spectatorObj[key], parentTitle)}>
                        <SearchRounded />
                        </IconButton>
                        </span>
                  :
                  renderContent(spectatorObj[key], parentTitle, handleOpen)
                }</TableCell>
              </TableRow>
            );
          })
          : <TableRow>
            {spectatorObj ?
              Object.keys(spectatorObj).map(obj => {
                return <TableCell key={obj}>{typeof spectatorObj[obj] === 'object' ?
                  <span>Test
                        <IconButton size="small" onClick={() => handleOpen(spectatorObj[obj], parentTitle)}>
                        <Add />
                        </IconButton>
                        </span>
                  : renderContent(spectatorObj[obj], parentTitle, handleOpen)}
                </TableCell>;
              })
              : ''}
          </TableRow>}

      </TableBody>
    );
  };

  return (
    <Table>
      {generateTableHead()}
      {generateTableBody()}
    </Table>
  );
};